import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useApp } from 'contexts/app-context';
import { useQuery } from 'hooks';
import * as logger from 'utils/logger';

type AccountContext = {
	type: string;
	account: any;
	isAuthenticated: () => boolean;
	rootApi: string;
	rootLink: string;
	searchParams: any;
	loading: boolean;
	fetchNotificationCount: any;
	onSwitch: any;
	onUpdate: any;
	onRefresh: any;
	loaded: boolean;
};

export const AccountContext = React.createContext<AccountContext>(null);

const AccountProvider = ({ children }) => {
	const { session, storage, store, toast } = useApp();
	const [type, setType] = useState('');
	const [account, setAccount] = useState<any>({});
	const [loaded, setLoaded] = useState(false);
	const router = useRouter();

	// Computed
	const rootApi = `/account`;
	const rootLink = type === 'User' ? `/${session.user.uuid}` : `/teams/${account.uuid}`;
	const searchParams = { 'owner.uuid': account.uuid };
	const isAuthenticated = () => (account.id ? true : false);

	// Hooks
	useEffect(() => {
		if (session.loaded) {
			if (session.user.id) {
				const type = storage.storage.type || 'User';
				const account = type === 'Team' ? storage.storage.account : session.user;
				setType(type);
				setAccount(account);
				storage.storeValues({ type, account });
				storage.storeCookies({ accountType: type, account: account.uuid });
				setLoaded(true);
			} else {
				setType('');
				setAccount({});
			}
		}
	}, [session.loaded, session.user.id]);

	useEffect(() => {
		if (account.id && loaded && type === 'Team') fetchData();
	}, [account.id, loaded]);

	useEffect(() => {
		if (account.id) logger.debug('accountContext: ', account);
	}, [account]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		const response = await store.queryRecord({
			url: rootApi,
			params: { include: ['location', 'owner', 'setup', 'subscription.[price, product]'] },
		});
		setAccount(response.data);
	});

	const [fetchNotificationCount, notificationsLoading] = useQuery(async () => {
		const response = await store.queryRecord({ url: rootApi + '/notifications-count' });
		setAccount({ ...account, totalNotifications: response.data.totalNotifications });
	});

	// Methods
	const onSwitch = (type, account, reload = true) => {
		setType(type);
		setAccount(account);
		storage.storeValues({ type, account });
		storage.storeCookies({ accountType: type, account: account.uuid });
		if (reload) router.push(router.asPath);
	};

	const onUpdate = data => {
		setAccount({ ...account, ...data });
	};

	const onRefresh = data => {
		fetchData();
	};

	const onClear = () => {
		setType('User');
		setAccount({});
		setLoaded(false);
	};

	// Render
	return (
		<AccountContext.Provider
			value={{
				type,
				account,
				isAuthenticated,
				rootApi,
				rootLink,
				searchParams,
				loading,
				fetchNotificationCount,
				onSwitch,
				onUpdate,
				onRefresh,
				loaded,
			}}>
			{children}
		</AccountContext.Provider>
	);
};

const useAccount = () => {
	return React.useContext(AccountContext);
};

export { AccountProvider, useAccount };
