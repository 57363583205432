import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardIcon,
	CardImg,
	CardPretitle,
	CardSubtitle,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { LinkWrapper } from '@playbooks/interface/links';
import { Skeleton } from 'molecules/skeletons';

const GridCard = ({
	icon,
	header = null,
	photo,
	status,
	pretitle,
	title,
	subtitle,
	text,
	href,
	meta,
	loading,
	tailwind,
	children,
}) => {
	// Render
	if (loading) return <Skeleton type='grid' tailwind={tailwind} />;
	return (
		<Card
			// border=''
			display='flex-column'
			overflow='overflow-hidden'
			height='h-full'
			hover='h:border-opacity-100 dark:h:border-opacity-100'
			{...tailwind?.card}>
			<LinkWrapper href={href}>
				{header ? (
					header
				) : (
					<CardHeader
						aspect='aspect-[3/2]'
						borderRadius=''
						overflow='overflow-hidden'
						position='relative'
						width='w-full'
						{...tailwind?.header}>
						{photo ? (
							<CardImg
								src={photo}
								alt={title + ' photo'}
								position='absolute'
								scale='h:scale-[105%]'
								transition='transition-all'
								ease='ease'
								{...tailwind?.photo}
							/>
						) : (
							<CardIcon
								icon={icon}
								position='absolute'
								display='flex-middle'
								inset='inset-0'
								{...icon}
								{...tailwind?.icon}
							/>
						)}
					</CardHeader>
				)}
			</LinkWrapper>
			<CardBody flex='grow' space='space-y-2' {...tailwind?.body}>
				{pretitle && <CardPretitle fontSize='text-sm'>{pretitle}</CardPretitle>}
				{title && (
					<LinkWrapper href={href} textTransform='h:underline'>
						<CardTitle size='h4'>{title}</CardTitle>
					</LinkWrapper>
				)}
				{subtitle && <CardSubtitle className='line-clamp-4'>{subtitle}</CardSubtitle>}
				{text && <CardText>{text}</CardText>}
				{meta}
			</CardBody>
			{children && (
				<CardFooter display='flex-start' {...tailwind?.footer}>
					{children}
				</CardFooter>
			)}
		</Card>
	);
};

export { GridCard };
