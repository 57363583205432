import { Badge } from '@playbooks/interface/badges';
import {
	Card,
	CardActions,
	CardBody,
	CardHeader,
	CardIcon,
	CardImg,
	CardPretitle,
	CardSubtitle,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { LinkWrapper } from '@playbooks/interface/links';
import { Skeleton } from 'molecules/skeletons';

const ListCard = ({
	icon,
	photo,
	header = null,
	badges,
	status,
	pretitle,
	title,
	subtitle,
	text,
	href,
	meta,
	loading,
	tailwind,
	children,
}) => {
	// Render
	if (loading) return <Skeleton type='list' tailwind={tailwind} />;
	return (
		<Card
			display='md:flex-between'
			hover='h:border-opacity-100 dark:h:border-opacity-100'
			space='space-x-8'
			spacing='p-4'
			{...tailwind?.card}>
			<Span display='flex-start' space='space-x-4' width='w-full'>
				<LinkWrapper href={href}>
					{header ? (
						header
					) : (
						<CardHeader
							aspect='aspect-[1/1]'
							borderRadius='rounded-md'
							display='flex-middle'
							overflow='overflow-hidden'
							spacing=''
							width='w-20 lg:w-24'
							{...tailwind?.header}>
							{photo ? (
								<CardImg src={photo} alt={title + ' photo'} width='w-full' {...tailwind?.photo} />
							) : (
								<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
							)}
						</CardHeader>
					)}
				</LinkWrapper>
				<CardBody display='flex-column' spacing='' width='w-full' {...tailwind?.body}>
					{pretitle && <CardPretitle>{pretitle}</CardPretitle>}
					<Span display='flex-start' space='space-x-4'>
						<CardTitle>
							<LinkWrapper href={href} hover='h:underline'>
								{title}
							</LinkWrapper>
						</CardTitle>
						<Span display='flex-start' space='space-x-2'>
							{badges}
							{status && (
								<Badge size='xs' type={status}>
									{status}
								</Badge>
							)}
						</Span>
					</Span>
					{subtitle && <CardSubtitle className='line-clamp-2'>{subtitle}</CardSubtitle>}
					{text && <CardText>{text}</CardText>}
					{meta}
				</CardBody>
			</Span>
			{children && (
				<CardActions display='hidden md:flex-end' flex='shrink-0'>
					{children}
				</CardActions>
			)}
		</Card>
	);
};

export { ListCard };
