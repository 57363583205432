import { TeamAvatar } from 'components/team/team-avatar';
import { UserAvatar } from 'components/user/user-avatar';

export const OwnerAvatar = ({
	type,
	owner = {},
	variant = '',
	loading,
	rootLink = '',
	tailwind,
}: {
	type: string;
	owner: any;
	variant?: string;
	loading?: boolean;
	rootLink?: string;
	tailwind?: any;
}) => {
	const props = { type, loading, rootLink, tailwind };
	return variant === 'Team' ? <TeamAvatar team={owner} {...props} /> : <UserAvatar user={owner} {...props} />;
};
