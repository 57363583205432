import { useRouter } from 'next/router';

import { BtnGroup } from '@playbooks/interface/button-groups';
import { AccentBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import { toShortNumber } from 'utils';

const AppToggle = ({ keyName = 'content', meta }: { keyName?: string; meta?: any }) => {
	const router = useRouter();
	const { content = '' } = router.query;

	// Methods
	const setRouterFilter = prop => {
		const query = { ...router.query, ...prop };
		router.push({ query }, undefined, { shallow: true });
	};

	// Render
	return (
		<BtnGroup divide='' width='w-full lg:w-auto'>
			<Span width='w-1/2'>
				<AccentBtn
					size='sm'
					borderRadius=''
					alt='toggle repo'
					active={content === ''}
					span={{ space: 'space-x-2' }}
					onClick={() => setRouterFilter({ [keyName]: '' })}
					className='w-full'>
					<Span>Repos</Span>
					{meta && <Span>{toShortNumber(meta.totalRepos)}</Span>}
				</AccentBtn>
			</Span>
			<Span width='w-1/2'>
				<AccentBtn
					size='sm'
					borderRadius=''
					alt='toggle bounty'
					active={content === 'bounties'}
					span={{ space: 'space-x-2' }}
					onClick={() => setRouterFilter({ [keyName]: 'bounties' })}
					className='w-full'>
					<Span>Bounties</Span>
					{meta && <Span>{toShortNumber(meta.totalBounties)}</Span>}
				</AccentBtn>
			</Span>
		</BtnGroup>
	);
};

export { AppToggle };
