import { Span } from '@playbooks/interface/html';
import { tailwindClassBuilder } from '@playbooks/interface/utils';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

export const BasicSkeleton = ({ theme, className, tailwind = {} }) => {
	const classes = tailwindClassBuilder({ ...tailwind, className });
	return (
		<Span display='inline-block' className={classes}>
			<SkeletonWrapper theme={theme} className='w-full' />
		</Span>
	);
};
