import { BorderLink } from '@playbooks/interface/links';
import { UserBadges } from 'components/user/user-badges';
import { UserCardActions } from 'components/user/user-card-actions';
import {
	ActionCard,
	DetailCard,
	DisplayCard,
	ListCard,
	PhotoLinkCard,
	PillCard,
	PreviewCard,
	ProfileCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';

export const UserActionCard = ({ user, loading, taskRunning, onClick, tailwind }) => {
	// Render
	return (
		<ActionCard
			icon='circle-user'
			title={user.name}
			subtitle={user.tagline || '--'}
			loading={loading}
			tailwind={tailwind}>
			<UserCardActions type='action' user={user} taskRunning={taskRunning} onClick={onClick} />
		</ActionCard>
	);
};

export const UserDetailCard = ({ user, loading, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='circle-user'
			status={user.status}
			cover={user.cover}
			photo={user.thumbnail}
			title={user.name}
			subtitle={'@' + user.uuid}
			text={user.tagline || '--'}
			footer={user.updatedAt}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const UserDisplayCard = ({ user, rootLink, children, tailwind }) => {
	// Render
	return (
		<DisplayCard
			icon='circle-user'
			badges={<UserBadges user={user} />}
			photo={user.thumbnail}
			title={user.name}
			subtitle={'@' + user.uuid}
			text={user.tagline || '--'}
			href={`${rootLink}/${user.uuid}`}
			tailwind={tailwind}>
			{children ? children : <UserCardActions type='grid' user={user} rootLink={rootLink} />}
		</DisplayCard>
	);
};

export const UserListCard = ({ user, loading, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='circle-user'
			badges={<UserBadges user={user} />}
			photo={user.thumbnail}
			title={user.name}
			subtitle={user.tagline || '--'}
			loading={loading}
			href={`${rootLink}/${user.uuid}`}
			tailwind={tailwind}>
			<UserCardActions type='list' user={user} rootLink={rootLink} />
		</ListCard>
	);
};

export const UserPhotoLinkCard = ({ user, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PhotoLinkCard
			icon='circle-user'
			photo={user.thumbnail}
			title={user.name}
			subtitle={user.tagline}
			href={`${rootLink}/${user.uuid}`}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const UserPillCard = ({ user, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PillCard
			icon='circle-user'
			photo={user.thumbnail}
			title={user.name}
			text={user.tagline}
			href={`${rootLink}/${user.uuid}`}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const UserPreviewCard = ({ user, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='circle-user'
			photo={user.thumbnail}
			title={user.name}
			text={user.uuid}
			loading={loading}
			href={`${rootLink}/${user.uuid}`}
			tailwind={tailwind}>
			<BorderLink size='icon' icon='chevron-right' href={`${rootLink}/${user.uuid}`} />
		</PreviewCard>
	);
};

export const UserProfileCard = ({ user, rootLink, tailwind }) => {
	// Render
	return (
		<ProfileCard
			icon='circle-user'
			photo={user.thumbnail}
			title={user.name}
			subtitle={'@' + user.uuid}
			href={`${rootLink}/${user.uuid}`}
			tailwind={tailwind}>
			<UserCardActions type='grid' user={user} rootLink={rootLink} />
		</ProfileCard>
	);
};

export const UserSearchCard = ({ user, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='circle-user'
			modelName='user'
			photo={user.thumbnail}
			title={user.name}
			subtitle={'@' + user.uuid}
			onClick={() => onClick(user)}
			tailwind={tailwind}
		/>
	);
};

export const UserSelectCard = ({ user, selected = [], loading, onClick, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='circle-user'
			photo={user.thumbnail}
			title={user.name}
			subtitle={'@' + user.uuid}
			selected={selected.map(v => v.id).includes(user.id)}
			loading={loading}
			onClick={() => onClick(user)}
			tailwind={tailwind}
		/>
	);
};
