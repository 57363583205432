import { useState } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { TextBtn } from '@playbooks/interface/buttons';
import { Card, CardBody, CardHeader, CardSubtitle, CardText, CardTitle } from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { OwnerAvatar } from 'components/owner/owner-avatar';
import { StarRatingWrapper } from 'components/wrappers/star-rating-wrapper';
import { Skeleton } from 'molecules/skeletons';
import { formatDate, truncate } from 'utils';

const RatingCard = ({ owner, ownerType, verified, badges, rating, title, text, date, loading, children, tailwind }) => {
	const [open, setOpen] = useState(false);
	const onToggle = () => setOpen(!open);

	// Render
	if (loading) return <Skeleton type='rating' tailwind={tailwind} />;
	return (
		<Card border='border-b' borderRadius='' spacing='py-2' {...tailwind?.card}>
			<CardHeader bgColor='' border='' display='flex-between' space='space-x-4' spacing='py-2'>
				<Span display='flex-start' space='space-x-2'>
					<OwnerAvatar type='meta' owner={owner} variant={ownerType} />
					<CardText color='gray-600 dark:gray-400'>on {formatDate(date, 'MMM, Do YYYY')}</CardText>
				</Span>
				<Span display='flex-end' space='space-x-2'>
					{verified && (
						<Badge borderColor='border-green-500' color='green-500'>
							<FadIcon icon='check' />
							<Span>Verified</Span>
						</Badge>
					)}
					{children}
				</Span>
			</CardHeader>
			<CardBody space='space-y-2' spacing='pb-2'>
				<StarRatingWrapper value={rating} dimensions='18px' spacing='4px' />
				<CardTitle size='h6'>
					<Span>{title}</Span>
					{badges}
				</CardTitle>
				<CardSubtitle space='space-x-2'>
					<Span>{text.length > 120 ? truncate(text, open ? 10000 : 120) : text}</Span>
					{text.length > 120 && (
						<TextBtn size='' fontSize='text-sm' onClick={onToggle}>
							{open ? 'Read less' : 'Read more'}
						</TextBtn>
					)}
				</CardSubtitle>
			</CardBody>
		</Card>
	);
};

export { RatingCard };
