import { Col, Grid } from '@playbooks/interface/grid';
import { Li } from '@playbooks/interface/html';
import { SectionSubtitle } from '@playbooks/interface/sections';
import { StatProgressBar } from 'molecules/progress-bars';
import { Skeleton } from 'molecules/skeletons';

export const ProgressBarItem = ({ keyName, loading, value, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<Grid gap=''>
				<Col>{keyName && <SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>}</Col>
				<Col display='flex'>{loading ? <Skeleton className='w-20' /> : <StatProgressBar rating={value} />}</Col>
			</Grid>
		</Li>
	);
};
