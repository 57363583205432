import { useEffect, useState } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Drop, DropBtn, DropItem, DropList, DropMenu, DropToggle } from '@playbooks/interface/drops';
import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { capitalize } from 'utils';

const FacetSelectDrop = ({ id, value, metric, placeholder, options, onChange, ...tailwind }) => {
	const [open, setOpen] = useState(false);

	// Hooks
	useEffect(() => {
		// log('options: ', options);
	}, [options]);

	// Methods
	const onClose = () => setOpen(false);

	const onToggle = () => setOpen(!open);

	const onClick = option => {
		setOpen(false);
		onChange(option);
	};

	// Render
	return (
		<Drop open={open} onClose={onClose}>
			<DropToggle
				id={id}
				type='border'
				display='flex-start'
				onClick={onToggle}
				span={{ display: 'flex-start', flex: 'grow' }}
				{...tailwind}>
				<Span display='flex-between' space='space-x-4'>
					<Span>{capitalize(value)}</Span>
					{metric && <Badge>{metric}</Badge>}
				</Span>
			</DropToggle>
			<DropMenu open={open} location='left-0' maxHeight='max-h-[300px]'>
				<DropList>
					{options?.map((option, i) => (
						<DropItem key={i}>
							<DropBtn onClick={() => onClick(option)} span={{ display: 'flex-between', width: 'w-full' }}>
								<Span>{capitalize(option.name)}</Span>
								{value === option.name ? <FarIcon icon='check' /> : <Badge>{option.value}</Badge>}
							</DropBtn>
						</DropItem>
					))}
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { FacetSelectDrop };
