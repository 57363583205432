import {
	ActionSkeleton,
	ActivitySkeleton,
	AvatarSkeleton,
	BasicSkeleton,
	CommentSkeleton,
	ContentSkeleton,
	DetailSkeleton,
	DisplaySkeleton,
	DropSkeleton,
	FeaturedSkeleton,
	FilterSkeleton,
	GridSkeleton,
	ItemSkeleton,
	ListSkeleton,
	LogSkeleton,
	PhotoSkeleton,
	PillSkeleton,
	PreviewSkeleton,
	ProfileSkeleton,
	RatingSkeleton,
	SearchSkeleton,
	SectionSkeleton,
	SelectSkeleton,
	StatSkeleton,
	SubmitSkeleton,
	TableSkeleton,
	ThumbnailSkeleton,
	UpvoteSkeleton,
} from 'molecules/skeletons';

export const Skeleton = props => {
	switch (props.type) {
		case 'action':
			return <ActionSkeleton {...props} />;

		case 'activity':
			return <ActivitySkeleton {...props} />;

		case 'avatar':
			return <AvatarSkeleton {...props} />;

		case 'comment':
			return <CommentSkeleton {...props} />;

		case 'content':
			return <ContentSkeleton {...props} />;

		case 'detail':
			return <DetailSkeleton {...props} />;

		case 'display':
			return <DisplaySkeleton {...props} />;

		case 'drop':
			return <DropSkeleton {...props} />;

		case 'featured':
			return <FeaturedSkeleton {...props} />;

		case 'filter':
			return <FilterSkeleton {...props} />;

		case 'grid':
			return <GridSkeleton {...props} />;

		case 'grid-preview':
			return <PillSkeleton {...props} />;

		case 'item':
			return <ItemSkeleton {...props} />;

		case 'list':
			return <ListSkeleton {...props} />;

		case 'log':
			return <LogSkeleton {...props} />;

		case 'photo':
			return <PhotoSkeleton {...props} />;

		case 'pill':
			return <PillSkeleton {...props} />;

		case 'preview':
		case 'preview-action':
			return <PreviewSkeleton {...props} />;

		case 'profile':
			return <ProfileSkeleton {...props} />;

		case 'rating':
			return <RatingSkeleton {...props} />;

		case 'search':
			return <SearchSkeleton {...props} />;

		case 'section':
			return <SectionSkeleton {...props} />;

		case 'select':
			return <SelectSkeleton {...props} />;

		case 'stat':
			return <StatSkeleton {...props} />;

		case 'submit':
			return <SubmitSkeleton {...props} />;

		case 'table':
			return <TableSkeleton {...props} />;

		case 'thumbnail':
			return <ThumbnailSkeleton {...props} />;

		case 'upvote':
			return <UpvoteSkeleton {...props} />;

		default:
			return <BasicSkeleton {...props} />;
	}
};

// Docs:
// https://github.com/dvtng/react-loading-s#readme
