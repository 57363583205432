import { H5 } from '@playbooks/interface/fonts';
import { Li } from '@playbooks/interface/html';
import { SectionSubtitle } from '@playbooks/interface/sections';
import { Skeleton } from 'molecules/skeletons';

export const StatItem = ({ keyName, loading, value, options, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-between' space='space-x-8' spacing='py-4' {...tailwind?.li}>
			<SectionSubtitle fontSize='text-sm' width='w-1/2' {...tailwind?.key}>
				{keyName}
			</SectionSubtitle>

			{loading ? <Skeleton className='w-20' /> : <H5 align='text-right'>{value}</H5>}
		</Li>
	);
};

// Docs
// https://www.npmjs.com/package/react-countup#more-examples
