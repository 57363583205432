import { Div } from '@playbooks/interface/html';
import { AccentLink } from '@playbooks/interface/links';
import { metaCardProps } from '@playbooks/interface/utils';

const BountyCardMeta = ({ bounty, tailwind = {} }) => {
	// Render
	return (
		<Div display='flex-start flex-wrap' spacing='py-1' {...tailwind}>
			{bounty.platform && (
				<AccentLink href={`/platforms/${bounty.platform?.uuid}?view=bounties`} {...metaCardProps}>
					{bounty.platform?.name}
				</AccentLink>
			)}
			{bounty.topic && (
				<AccentLink href={`/topics/${bounty.topic?.uuid}?view=bounties`} {...metaCardProps}>
					{bounty.topic?.name}
				</AccentLink>
			)}
			{bounty.language && (
				<AccentLink href={`/languages/${bounty.language?.uuid}?view=bounties`} {...metaCardProps}>
					{bounty.language?.name}
				</AccentLink>
			)}
			{bounty.framework && (
				<AccentLink href={`/frameworks/${bounty.framework?.uuid}?view=bounties`} {...metaCardProps}>
					{bounty.framework?.name}
				</AccentLink>
			)}
		</Div>
	);
};

export { BountyCardMeta };
