import React, { useEffect, useState } from 'react';

import { PageLoader } from '@playbooks/interface/loaders';
import { useQuery } from 'hooks';
import { timeout } from 'utils';
import * as logger from 'utils/logger';

type SubmitSubmissionContext = {
	submission: any;
	setSubmission: any;
	rootApi: string;
	view: string;
	onView: any;
};

export const SubmitSubmissionContext = React.createContext<SubmitSubmissionContext>(null);

const SubmitSubmissionProvider = ({ account, entity, router, session, store, toast, children }) => {
	const [submission, setSubmission] = useState<any>({});
	const { submissionId, view = 'welcome' } = router.query;

	// Computed
	const rootApi = `${account.rootApi}/submissions/${submissionId}`;

	// Hooks
	useEffect(() => {
		if (session.loaded) session.isAuthenticated() ? fetchData() : router.push('/login');
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		logger.debug('submissionContext: ', submission);
	}, [submission]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		await timeout(600);
		const response = await store.queryRecord({
			url: rootApi,
			params: {
				invalidate: true,
				include: [
					'bounty',
					'demo.[dockerImage]',
					'framework',
					'language',
					'license',
					'medias',
					'platform',
					'quality',
					'setup',
					'template',
					'tools',
					'topics',
				],
			},
		});
		setSubmission(response.data);
	});

	useEffect(() => {
		if (error) router.push('/403');
	}, [error]);

	// Methods
	const onView = view => {
		router.push(`${entity.rootLink}/add/${submission.uuid}?view=${view}`);
		window.scrollTo(0, 0);
	};

	const onClear = () => {
		setSubmission({});
	};

	// Render
	return (
		<SubmitSubmissionContext.Provider value={{ submission, setSubmission, rootApi, view, onView }}>
			{submission.id ? children : <PageLoader title='Hang Tight' message='Loading your workflow...' />}
		</SubmitSubmissionContext.Provider>
	);
};

const useSubmitSubmission = () => {
	return React.useContext(SubmitSubmissionContext);
};

export { SubmitSubmissionProvider, useSubmitSubmission };
