import { Fragment } from 'react';

import { Col, Grid } from '@playbooks/interface/grid';
import { Feedback } from 'molecules/feedbacks';
import { Skeleton } from 'molecules/skeletons';
import { listBuilder } from 'utils';

const RatingList = ({
	type = 'rating',
	icon = 'database',
	title = '',
	text = '',
	data = [],
	renderItem,
	count = 3,
	selected = [],
	loading = false,
	taskRunning = false,
	rootLink = '',
	onClick,
	onNext,
	children,
	tailwind,
}) => {
	const props = { selected, loading, taskRunning, rootLink, onClick, onNext, children, tailwind };
	const RenderItem = props => renderItem(props);

	// Render
	return (
		<Fragment>
			{data.length > 0 || loading ? (
				<Grid {...tailwind?.grid}>
					{loading ? (
						<Fragment>
							{listBuilder(count).map((v, i) => (
								<Col key={i}>
									<Skeleton type={type} tailwind={tailwind} />
								</Col>
							))}
						</Fragment>
					) : (
						<Fragment>
							{data?.map((item, i) => (
								<Col key={i}>
									<RenderItem item={item} {...props} />
								</Col>
							))}
							{children && <Col {...tailwind?.col}>{children}</Col>}
						</Fragment>
					)}
				</Grid>
			) : (
				<Feedback type={type} icon={icon} title={title} text={text} tailwind={tailwind} />
			)}
		</Fragment>
	);
};

export { RatingList };
