import React, { useEffect, useState } from 'react';

import { commentParams, discussionDetailParams } from 'api';
import { useQuery } from 'hooks';
import * as logger from 'utils/logger';

type DiscussionContext = {
	discussion: any;
	setDiscussion: any;
	comments: any;
	meta: any;
	isOwner: boolean;
	rootApi: string;
	rootLink: string;
	fetchData: () => void;
	loading: boolean;
	onRefresh: any;
};

const DiscussionContext = React.createContext<DiscussionContext>(null);

const DiscussionProvider = ({ ssr, account, type, entity, router, session, store, toast, children }) => {
	const [discussion, setDiscussion] = useState(ssr.discussion?.data || {});
	const [comments, setComments] = useState(ssr.comments?.data || {});
	const [meta, setMeta] = useState(ssr.comments?.meta || { page: 0, pageSize: 0, totalRecords: 0 });
	const [loaded, setLoaded] = useState(false);
	const { discussionId } = router.query;

	// Computed
	const rootApi = discussionId ? `${entity.rootApi}/discussions/${discussionId}` : `${entity.rootApi}/discussion`;
	const rootLink = `${entity.rootLink}/discussions/${discussionId}`;
	const isOwner = account.account?.id === discussion.ownerId && account.type === discussion.ownerType;

	// Hooks
	useEffect(() => {
		setLoaded(true);
		return () => onClear();
	}, []);

	useEffect(() => {
		if (loaded) setDiscussion(ssr.discussion?.data || {});
	}, [ssr.discussion]);

	useEffect(() => {
		if (discussion.id) logger.debug('discussionContext: ', discussion);
	}, [discussion]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		await fetchDiscussion();
		await fetchComments();
	});

	const fetchDiscussion = async () => {
		const response = await store.queryRecord({ url: rootApi, params: discussionDetailParams });
		setDiscussion(response.data);
	};

	const fetchComments = async () => {
		const response = await store.query({ url: rootApi + '/comments', params: commentParams });
		setComments(response.data);
		setMeta(response.meta);
	};

	// Methods
	const onRefresh = () => fetchData();

	const onClear = () => {
		setDiscussion({});
	};

	// Render
	return (
		<DiscussionContext.Provider
			value={{
				discussion,
				setDiscussion,
				comments,
				meta,
				isOwner,
				rootApi,
				rootLink,
				fetchData,
				loading,
				onRefresh,
			}}>
			{children}
		</DiscussionContext.Provider>
	);
};

const useDiscussion = () => {
	return React.useContext(DiscussionContext);
};

export { DiscussionProvider, useDiscussion };
