import { Card, CardBody, CardHeader, CardSubtitle, CardText, CardTitle } from '@playbooks/interface/cards';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const RatingSkeleton = ({ count, tailwind }) => (
	<Card border='border-b' borderRadius='' spacing='py-2' {...tailwind?.card}>
		<CardHeader bgColor='' border='' display='flex-start' space='space-x-2' spacing='py-2' width='w-full'>
			<CardText className='w-10'>
				<SkeletonWrapper />
			</CardText>
			<CardText className='w-20'>
				<SkeletonWrapper />
			</CardText>
		</CardHeader>
		<CardBody space='space-y-2' spacing='py-2'>
			<CardTitle display='' {...tailwind?.title}>
				<SkeletonWrapper width='25%' />
			</CardTitle>
			<CardSubtitle {...tailwind?.subtitle}>
				<SkeletonWrapper width='50%' />
			</CardSubtitle>
		</CardBody>
	</Card>
);

export { RatingSkeleton };
