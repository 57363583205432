import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { useAccount } from 'contexts';

const UserBadges = ({ type = 'card', user }) => {
	const account = useAccount();

	return (
		<Fragment>
			{user.role !== 'basic' && (
				<Badge>
					<Span>{user.role}</Span>
				</Badge>
			)}
			{user.visibility === 'private' && (
				<Badge>
					<FadIcon icon='lock' />
				</Badge>
			)}
			{user.isVerified && (
				<Badge>
					<FadIcon icon='circle-check' color='green-500' />
				</Badge>
			)}
			{user.status === 'disabled' && (
				<Badge>
					<Span>{user.status}</Span>
				</Badge>
			)}
			{type === 'hero' && account.type === 'User' && account.account.uuid === user.uuid && <Badge>This is you</Badge>}
		</Fragment>
	);
};

export { UserBadges };
