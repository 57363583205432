import { Fragment, useState } from 'react';

import { Span } from '@playbooks/interface/html';
import { Navbar, NavbarBrand } from '@playbooks/interface/navbars';
import { AppNavbarList } from 'components/app/app-navbar-list';
import { AppSearch } from 'components/app/app-search';
import { BrowseMenu } from 'components/browse/browse-menu';
import { useTheme } from 'contexts';

const AppNav = ({ ...tailwind }) => {
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	// Computes
	const BrandLogo = '/branding/playbooks-logo.svg';
	const BrandLogoDark = '/branding/playbooks-logo-dark.svg';

	// Render
	return (
		<Fragment>
			<Navbar border='border-b' zIndex='z-10' {...tailwind}>
				<Span display='flex-start' flex='grow' space='space-x-6'>
					<NavbarBrand src={theme.isDark ? BrandLogoDark : BrandLogo} hover='transition-all ease h:opacity-75' />
					<AppSearch />
				</Span>
				<AppNavbarList open={open} setOpen={setOpen} />
			</Navbar>
			<BrowseMenu open={open} setOpen={setOpen} />
		</Fragment>
	);
};

export { AppNav };
