import { Fragment } from 'react';

import {
	Card,
	CardActions,
	CardBody,
	CardHeader,
	CardIcon,
	CardImg,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { LinkWrapper } from '@playbooks/interface/links';
import { Skeleton } from 'molecules/skeletons';

const PillCard = ({ icon, header = null, photo = null, title, text, href, loading, children, tailwind }) => {
	// Render
	if (loading) return <Skeleton type='pill' tailwind={tailwind} />;
	return (
		<Card
			display='flex-column'
			height='h-full'
			hover='h:border-opacity-100 dark:h:border-opacity-100'
			space='space-x-4'
			spacing='p-4'
			{...tailwind?.card}>
			<Span display='flex-start' flex='grow' space='space-x-4' width='w-full'>
				<LinkWrapper href={href}>
					{header ? (
						<Fragment>{header}</Fragment>
					) : (
						<CardHeader
							aspect='aspect-[1/1]'
							borderRadius='rounded-md'
							display='flex-middle'
							overflow='overflow-hidden'
							spacing=''
							width='w-16'
							{...tailwind?.header}>
							{photo ? (
								<CardImg src={photo} alt={title + ' photo'} width='w-full' {...tailwind?.photo} />
							) : (
								<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
							)}
						</CardHeader>
					)}
				</LinkWrapper>
				<CardBody align='text-left' flex='grow' spacing='' {...tailwind?.body}>
					{title && (
						<LinkWrapper href={href} textDecoration='h:underline'>
							<CardTitle {...tailwind?.title}>{title}</CardTitle>
						</LinkWrapper>
					)}
					{text && (
						<CardText className='line-clamp-2' {...tailwind?.subtitle}>
							{text}
						</CardText>
					)}
				</CardBody>
			</Span>
			{children && (
				<CardActions display='hidden md:flex-end' flex='shrink-0'>
					{children}
				</CardActions>
			)}
		</Card>
	);
};

export { PillCard };
