import { Avatar } from 'molecules/avatars';
import { Skeleton } from 'molecules/skeletons';

export const UserAvatar = ({ type = 'card', user, loading = false, rootLink = '', tailwind }) => {
	const props = {
		icon: 'circle-user',
		thumbnail: user.thumbnail,
		title: user.name,
		subtitle: `@${user.uuid}`,
		href: `${rootLink}/users/${user.uuid}`,
		badge: user.membership !== 'free' ? user.membership : '',
		tailwind,
	};

	// Render
	if (loading) return <Skeleton className='w-20' />;
	return <Avatar type={type} {...props} />;
};
