import { Fragment, useEffect, useState } from 'react';

import { AuthBtn } from 'components/auth/auth-btn';
import { StackBtnFlow } from 'components/stack/stack-btn-flow';
import { useAccount } from 'contexts';

export const StackBtn = ({ type = 'border', model, variant, rootApi }) => {
	const [stack, setStack] = useState<any>({});
	const [open, setOpen] = useState(false);
	const account = useAccount();

	// Hooks
	useEffect(() => {
		setStack(model?.social?.stack || {});
	}, [model?.social]);

	// Methods
	const onClick = () => setOpen(true);

	const onRefresh = data => {
		setOpen(false);
		setStack(data?.social?.stack || {});
	};

	// Render
	return (
		<Fragment>
			<AuthBtn type={type} size='icon' icon='layer-group' alt='add to stack' onClick={onClick} />
			<StackBtnFlow type={variant} model={model} open={open} setOpen={setOpen} rootApi={rootApi} onNext={onRefresh} />
		</Fragment>
	);
};
