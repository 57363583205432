import { useEffect, useState } from 'react';

import { Small } from '@playbooks/interface/fonts';
import { Span } from '@playbooks/interface/html';
import { Progress, ProgressBar } from '@playbooks/interface/progress-bars';
import { timeout, toNumber } from 'utils';

const StatProgressBar = ({ rating, ratings = 5 }) => {
	const [progress, setProgress] = useState(0);

	// Hooks
	useEffect(() => {
		onDisplay();
	}, [rating]);

	// Methods
	const onDisplay = async () => {
		await timeout(300);
		setProgress((rating / ratings) * 100);
	};

	// Render
	return (
		<Span display='flex-end' space='space-x-4' width='w-full'>
			<ProgressBar>
				<Progress value={progress} />
			</ProgressBar>
			<Small>{toNumber(rating, 1)}</Small>
		</Span>
	);
};

export { StatProgressBar };
