import { Fragment } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import { BorderLink } from '@playbooks/interface/links';
import { BookmarkBtn } from 'components/bookmark/bookmark-btn';
import { OwnerAvatar } from 'components/owner/owner-avatar';
import { StackBtn } from 'components/stack/stack-btn';
import { useAccount } from 'contexts';

export const RepoCardActions = ({ type, repo, taskRunning = null, onClick = () => null }) => {
	const account = useAccount();
	const isOwner = account.account?.id === repo.ownerId && account.type === repo.ownerType;
	const props = { repo, taskRunning, isOwner, onClick };

	// Render
	switch (type) {
		case 'display':
			return <RepoDisplayCardActions {...props} />;

		case 'list':
			return <RepoListCardActions {...props} />;

		case 'submit':
			return <RepoSubmitCardActions {...props} />;
	}
};

export const RepoDisplayCardActions = ({ repo }) => (
	<Fragment>
		<OwnerAvatar type='card' variant={repo.ownerType} owner={repo.owner} />
		<Span display='flex-end' space='space-x-4'>
			<StackBtn type='border' model={repo} variant='repos' rootApi={`/repos/${repo.uuid}`} />
			<BookmarkBtn type='border' model={repo} rootApi={`/repos/${repo.uuid}`} />
		</Span>
	</Fragment>
);

export const RepoListCardActions = ({ repo }) => (
	<Fragment>
		<StackBtn type='border' model={repo} variant='repos' rootApi={`/repos/${repo.uuid}`} />
		<BookmarkBtn type='border' model={repo} rootApi={`/repos/${repo.uuid}`} />
	</Fragment>
);

export const RepoSubmitCardActions = ({ repo, taskRunning, onClick }) => (
	<Fragment>
		<BorderBtn
			size='icon'
			icon='trash'
			color='red-500'
			taskRunning={taskRunning?.id === repo.id}
			onClick={() => onClick(repo)}
		/>
		<BorderLink size='icon' icon='pencil' href={`/add/repo/${repo.uuid}`} />
	</Fragment>
);
