import {
	ActivityList,
	AvatarList,
	BtnList,
	CommentList,
	DisplayList,
	DropList,
	GridList,
	ItemList,
	LinkList,
	ListList,
	PhotoList,
	PillList,
	PreviewList,
	ProfileList,
	RatingList,
	SearchList,
	SelectList,
	SubmitList,
} from 'molecules/lists';

const List = ({
	type,
	icon = 'database',
	title = '',
	text = '',
	data = [],
	renderItem,
	count,
	loading,
	taskRunning,
	selected,
	rootLink,
	onClick,
	onNext,
	children = null,
	tailwind,
}) => {
	const defaultText = `We don't have any ${title} that match your criteria.`;
	const computedText = text ? text : defaultText;
	const props = {
		type,
		icon,
		title,
		text: computedText,
		data,
		renderItem,
		count,
		loading,
		taskRunning,
		selected,
		rootLink,
		onClick,
		onNext,
		children,
		tailwind,
	};

	// Render
	switch (type) {
		case 'action':
			return <ListList {...props} />;

		case 'activity':
			return <ActivityList {...props} />;

		case 'avatar':
			return <AvatarList {...props} />;

		case 'btn':
			return <BtnList {...props} />;

		case 'comment':
			return <CommentList {...props} />;

		case 'display':
			return <DisplayList {...props} />;

		case 'drop':
			return <DropList {...props} />;

		case 'item':
			return <ItemList {...props} />;

		case 'link':
			return <LinkList {...props} />;

		case 'list':
			return <ListList {...props} />;

		case 'grid':
			return <GridList {...props} />;

		case 'photo':
			return <PhotoList {...props} />;

		case 'pill':
			return <PillList {...props} />;

		case 'preview':
		case 'preview-action':
			return <PreviewList {...props} />;

		case 'profile':
			return <ProfileList {...props} />;

		case 'rating':
			return <RatingList {...props} />;

		case 'search':
			return <SearchList {...props} />;

		case 'select':
			return <SelectList {...props} />;

		case 'submit':
			return <SubmitList {...props} />;
	}
};

export { List };

// Docs
// https://react.dev/reference/react/cloneElement#passing-data-with-a-render-prop
