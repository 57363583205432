import { useEffect, useState } from 'react';

import { Drop, DropBtn, DropItem, DropList, DropMenu } from '@playbooks/interface/drops';
import { Span } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { SearchTag } from 'components/search/search-tag';
import { useQuery } from 'hooks';
import { SearchDivForm, SearchForm } from 'molecules/forms';
import { AlgoliaService } from 'services';
import { capitalize } from 'utils';

type FacetSearchDrop = {
	id?: string;
	keyName: string;
	tableName: string;
	prevIcon?: string;
	value: any;
	placeholder: string;
	onChange: any;
	tailwind?: any;
};

const FacetSearchDrop = ({
	id,
	keyName,
	tableName,
	prevIcon = 'filter',
	value,
	placeholder,
	onChange,
	tailwind,
}: FacetSearchDrop) => {
	const [options, setOptions] = useState([]);
	const [params, setParams] = useState<any>({});
	const [query, setQuery] = useState('');
	const [open, setOpen] = useState(false);

	// Computed
	const client = new AlgoliaService({ tableName });
	const isActive = options?.includes(value);

	// Hooks
	useEffect(() => {
		searchDb();
	}, [query]);

	// Actions
	const [searchDb, loading] = useQuery(async () => {
		const response = await client.queryFacet(keyName, query, params);
		setOptions(response.facetHits);
	});

	// Methods
	const onClose = () => setOpen(false);

	const onOpen = () => setOpen(true);

	const onClick = option => {
		setOpen(false);
		setQuery('');
		onChange(option);
	};

	// Render
	return (
		<Drop open={open} onClose={onClose} {...tailwind?.drop}>
			{value ? (
				<SearchDivForm prevIcon={prevIcon} tailwind={tailwind.search}>
					<SearchTag result={value} options={{ keyName }} onRemove={() => onClick(null)} />
				</SearchDivForm>
			) : (
				<SearchForm
					id={id}
					delay={100}
					prevIcon={prevIcon}
					query={query}
					setQuery={setQuery}
					placeholder={placeholder}
					onFocus={onOpen}
					onClear={() => onClick(null)}
					tailwind={tailwind?.search}
				/>
			)}
			<DropMenu open={open} location='left-0' maxHeight='max-h-[300px]'>
				<DropList>
					{options?.map((option, i) => (
						<DropItem key={i}>
							<DropBtn onClick={() => onClick(option)} span={{ display: 'flex-between', width: 'w-full' }}>
								<Span>{capitalize(option.value)}</Span>
								{value === option.value && <FarIcon icon='check' />}
							</DropBtn>
						</DropItem>
					))}
					{isActive && (
						<DropItem>
							<DropBtn nextIcon='xmark' onClick={() => onClick('')} span={{ display: 'flex-between', width: 'w-full' }}>
								Clear
							</DropBtn>
						</DropItem>
					)}
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { FacetSearchDrop };
