import {
	Card,
	CardActions,
	CardBody,
	CardHeader,
	CardIcon,
	CardImg,
	CardSubtitle,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { Skeleton } from 'molecules/skeletons';

const PillActionCard = ({ icon, photo, title, subtitle, text, loading, children, tailwind }) => {
	// Render
	if (loading) return <Skeleton type='pill' tailwind={tailwind} />;
	return (
		<Card
			display='flex-between'
			hover='h:border-opacity-100 dark:h:border-opacity-100'
			space='space-x-4'
			spacing='p-4'
			{...tailwind?.card}>
			<Span display='flex-start' space='space-x-4' width='w-full'>
				<CardHeader
					aspect='aspect-[1/1]'
					borderRadius='rounded-md'
					display='flex-middle'
					overflow='overflow-hidden'
					spacing=''
					width='w-16'
					{...tailwind?.header}>
					{photo ? (
						<CardImg src={photo} alt={title + ' photo'} width='w-full' {...tailwind?.photo} />
					) : (
						<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
					)}
				</CardHeader>
				<CardBody align='text-left' flex='grow' spacing='' {...tailwind?.body}>
					{title && <CardTitle {...tailwind?.title}>{title}</CardTitle>}
					{subtitle && <CardSubtitle {...tailwind?.subtitle}>{subtitle}</CardSubtitle>}
					{text && <CardText>{text}</CardText>}
				</CardBody>
			</Span>
			{children && (
				<CardActions display='flex-end' flex='shrink-0'>
					{children}
				</CardActions>
			)}
		</Card>
	);
};

export { PillActionCard };
