import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { Drop, DropMenu } from '@playbooks/interface/drops';
import { Form } from '@playbooks/interface/forms';
import { InputAppend } from '@playbooks/interface/input-groups';
import { appParams } from 'api';
import { SearchResultsPreview } from 'components/search/search-results-preview';
import { SearchResultsSubnav } from 'components/search/search-results-subnav';
import { useStore } from 'contexts';
import { useKeyPress, useQuery } from 'hooks';
import { SearchForm } from 'molecules/forms';
import { timeout } from 'utils';

const SearchDrop = ({ subnav, tableNames, placeholder = 'Search for anything...', tailwind }) => {
	const [query, setQuery] = useState('');
	const [tableName, setTableName] = useState('');
	const [results, setResults] = useState([]);
	const [params, setParams] = useState({ ...appParams, status: 'active', pageSize: 3 });
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const [open, setOpen] = useState(false);
	const ref = useRef(null);
	const router = useRouter();
	const store = useStore();

	// Hooks
	useEffect(() => {
		if (query.length >= 3) {
			setOpen(true);
			fetchData(query);
		}
	}, [params, query]);

	useEffect(() => {
		if (open) onClose();
	}, [router.asPath]);

	useEffect(() => {
		open ? onReady() : onClose();
	}, [open]);

	useKeyPress(onKeyPress, [router.asPath]);

	// Actions
	const [fetchData, loading] = useQuery(async () => {
		tableName ? await searchTable() : await searchTables();
	});

	// Functions
	function onKeyPress(e) {
		if (e.keyCode === 75 && e.metaKey) onOpen();
		if (e.keyCode === 27) onClose();
	}

	// Methods
	const searchTable = async () => {
		const response = await store.request({ url: '/search-table', params: { ...params, query, tableName } });
		setResults(response.data);
		setMeta(response.meta);
	};

	const searchTables = async () => {
		const response = await store.request({ url: '/search-tables', params: { ...params, query, tableNames } });
		const records = response.map(record => record.data.map(v => ({ index: record.index, ...v })));
		setResults(records.flat());
	};

	const onOpen = () => {
		setOpen(true);
	};

	const onReady = async () => {
		await timeout(300);
		if (ref.current) ref.current.focus();
	};

	const onSelect = tableName => {
		setTableName(tableName);
		setParams({ ...params, pageSize: tableName ? 5 : 3 });
	};

	const onClose = () => {
		if (ref.current) ref.current.blur();
		onClear();
		setOpen(false);
	};

	const onClear = () => {
		setQuery('');
		if (ref.current) ref.current.value = '';
		setResults([]);
	};

	const onSubmit = e => {
		e.preventDefault();
		switch (tableName) {
			case 'bounties':
				return router.push(`/search/bounties?query=${query}`);

			case 'stacks':
				return router.push(`/search/stacks?query=${query}`);

			case 'repos':
				return router.push(`/search/repos?query=${query}`);

			case 'teams':
				return router.push(`/search/teams?query=${query}`);

			case 'users':
				return router.push(`/search/users?query=${query}`);

			default:
				return router.push(`/search?query=${query}`);
		}
	};

	// Render
	return (
		<Drop open={open} onClose={onClose} width='w-full'>
			<Form onSubmit={onSubmit}>
				<SearchForm
					id='search_drop'
					ref={ref}
					delay={300}
					query={query}
					setQuery={setQuery}
					placeholder={placeholder}
					onFocus={onOpen}
					onClear={onClear}
					tailwind={tailwind?.search}
					elements={{
						inputAppend: (
							<InputAppend
								border='border'
								borderRadius='rounded-md'
								color='gray-400'
								fontSize='text-xs'
								spacing='m-2 px-2 py-1'
								onClick={() => query.length >= 3 && onClear()}>
								{open ? 'ESC' : '⌘K'}
							</InputAppend>
						),
					}}
				/>
			</Form>
			<DropMenu open={open} inset='left-0'>
				<SearchResultsSubnav tableName={tableName} tableNames={subnav} onSelect={onSelect} />
				<SearchResultsPreview
					query={query}
					hits={results}
					loading={loading}
					tableName={tableName}
					tableNames={tableNames}
				/>
			</DropMenu>
		</Drop>
	);
};

export { SearchDrop };
