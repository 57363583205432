import { Badge } from '@playbooks/interface/badges';
import { Card, CardActions, CardBody, CardSubtitle, CardText, CardTitle } from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { LinkWrapper } from '@playbooks/interface/links';
import { UpvoteBtn } from 'components/upvote/upvote-btn';
import { Skeleton } from 'molecules/skeletons';

const UpvoteCard = ({
	badges,
	status,
	title,
	subtitle,
	text,
	meta,
	href,
	model,
	rootApi,
	loading,
	tailwind,
	children,
}) => {
	// Render
	if (loading) return <Skeleton type='preview' tailwind={tailwind} />;
	return (
		<Card border='border-b' borderRadius='' display='flex-between' space='space-x-4' spacing='py-4' {...tailwind?.card}>
			<Span display='flex-start' flex='grow' space='space-x-4' {...tailwind?.span}>
				<UpvoteBtn type='card' model={model} rootApi={rootApi} />
				<CardBody display='flex-column' space='space-y-2' spacing='' width='w-full' {...tailwind?.body}>
					<Span display='flex-start' space='space-x-4'>
						<CardTitle display='sm:flex' {...tailwind?.title}>
							<LinkWrapper href={href} flex='order-2 sm:order-1' hover='h:underline'>
								{title}
							</LinkWrapper>
						</CardTitle>
						<Span display='flex-start' space='space-x-2'>
							{badges}
							{status && (
								<Badge size='xs' type={status}>
									{status}
								</Badge>
							)}
						</Span>
					</Span>
					{subtitle && <CardSubtitle {...tailwind?.subtitle}>{subtitle}</CardSubtitle>}
					{text && <CardText {...tailwind?.text}>{text}</CardText>}
					{meta}
				</CardBody>
			</Span>
			{children && (
				<CardActions display='hidden md:flex-end' flex='shrink-0'>
					{children}
				</CardActions>
			)}
		</Card>
	);
};

export { UpvoteCard };
