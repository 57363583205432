import { useEffect } from 'react';

export const useResize = (onResize, listeners) => {
	// Hooks
	useEffect(() => {
		onResize();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, [...listeners]);
};

// Docs
// https://www.geeksforgeeks.org/react-onresize-event/
