import { Fragment } from 'react';

import { CardHeader, CardIcon, CardImg } from '@playbooks/interface/cards';
import { Col, Grid } from '@playbooks/interface/grid';
import { Div } from '@playbooks/interface/html';

export const StackCardHeader = ({ type = 'grid', stack, tailwind = {} }) => {
	const items = [...(stack.repos || []), ...(stack.bounties || [])];
	const props = { stack, items, tailwind };
	// Render
	switch (type) {
		case 'display':
			return <StackDisplayCardHeader {...props} />;
		case 'detail':
			return <StackDetailCardHeader {...props} />;
		case 'list':
			return <StackListCardHeader {...props} />;
		case 'pill':
			return <StackPillCardHeader {...props} />;
		case 'preview':
			return <StackPreviewCardHeader {...props} />;
		case 'select':
			return <StackSelectCardHeader {...props} />;
	}
};

export const StackDisplayCardHeader = ({ stack, items, tailwind }) => (
	<Fragment>
		{items.length > 0 ? (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				overflow='overflow-hidden'
				spacing='m-4 p-2'
				width='w-20'
				{...tailwind?.header}>
				<Grid cols='grid-cols-2' gap='gap-2'>
					{items.slice(0, 3).map((item, i) => (
						<Col key={i} span='1'>
							<CardImg src={item.thumbnail} alt='Stack thumbnail' borderRadius='rounded-md' />
						</Col>
					))}
				</Grid>
			</CardHeader>
		) : (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				display='flex-middle'
				overflow='overflow-hidden'
				spacing='m-4 p-2'
				width='w-20'
				{...tailwind?.header}>
				{stack.thumbnail ? (
					<CardImg src={stack.thumbnail} alt='stack thumbnail' borderRadius='rounded-md' />
				) : (
					<CardIcon icon='layer-group' />
				)}
			</CardHeader>
		)}
	</Fragment>
);

export const StackDetailCardHeader = ({ stack, items, tailwind }) => (
	<Fragment>
		{items.length > 0 ? (
			<Div aspect='aspect-[1/1]' bgColor='bg-gray-100 dark:bg-gray-800' border='border' spacing='p-2' width='w-full'>
				<Grid cols='grid-cols-2' gap='gap-2'>
					{items.slice(0, 3).map((item, i) => (
						<Col key={i} span='1'>
							<CardImg src={item.thumbnail} alt='Stack thumbnail' borderRadius='rounded-md' />
						</Col>
					))}
				</Grid>
			</Div>
		) : (
			<Fragment>
				{stack.thumbnail ? (
					<CardImg src={stack.thumbnail} alt='stack thumbnail' borderRadius='rounded-md' />
				) : (
					<Div
						aspect='aspect-[1/1]'
						bgColor='bg-gray-100 dark:bg-gray-800'
						border='border'
						display='flex-middle'
						width='w-full'>
						<CardIcon icon='layer-group' />
					</Div>
				)}
			</Fragment>
		)}
	</Fragment>
);

export const StackListCardHeader = ({ stack, items, tailwind }) => (
	<Fragment>
		{items.length > 0 ? (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				overflow='overflow-hidden'
				spacing='p-2'
				width='w-20 lg:w-24'
				{...tailwind?.header}>
				<Grid gap='gap-1.5'>
					{items.slice(0, 3).map((item, i) => (
						<Col key={i} span='6'>
							<CardImg src={item.thumbnail} alt='Stack thumbnail' borderRadius='rounded-md' />
						</Col>
					))}
				</Grid>
			</CardHeader>
		) : (
			<CardHeader aspect='aspect-[1/1]' display='flex-middle' spacing='' width='w-20 lg:w-24'>
				{stack.thumbnail ? (
					<CardImg src={stack.thumbnail} alt='stack thumbnail' borderRadius='rounded-md' />
				) : (
					<CardIcon icon='layer-group' />
				)}
			</CardHeader>
		)}
	</Fragment>
);

export const StackPillCardHeader = ({ stack, items, tailwind }) => (
	<Fragment>
		{items.length > 0 ? (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				overflow='overflow-hidden'
				spacing='p-1'
				width='w-16'
				{...tailwind?.header}>
				<Grid gap='gap-1'>
					{items.slice(0, 3).map((item, i) => (
						<Col key={i} span='6'>
							<CardImg src={item.thumbnail} alt='Stack thumbnail' borderRadius='rounded-md' />
						</Col>
					))}
				</Grid>
			</CardHeader>
		) : (
			<CardHeader aspect='aspect-[1/1]' display='flex-middle' spacing='p-2' width='w-16'>
				{stack.thumbnail ? (
					<CardImg src={stack.thumbnail} alt='stack thumbnail' borderRadius='rounded-md' />
				) : (
					<CardIcon icon='layer-group' />
				)}
			</CardHeader>
		)}
	</Fragment>
);

export const StackPreviewCardHeader = ({ stack, items, tailwind }) => (
	<Fragment>
		{items.length > 0 ? (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				overflow='overflow-hidden'
				spacing='p-1'
				width='w-12'
				{...tailwind?.header}>
				<CardImg src={items[0]?.thumbnail} alt='Stack thumbnail' borderRadius='rounded-md' />
			</CardHeader>
		) : (
			<CardHeader aspect='aspect-[1/1]' display='flex-middle' spacing='p-2' width='w-12'>
				{stack.thumbnail ? (
					<CardImg src={stack.thumbnail} alt='stack thumbnail' borderRadius='rounded-md' />
				) : (
					<CardIcon icon='layer-group' />
				)}
			</CardHeader>
		)}
	</Fragment>
);

export const StackSelectCardHeader = ({ stack, items, tailwind }) => (
	<Fragment>
		{items.length > 0 ? (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				overflow='overflow-hidden'
				spacing='p-1'
				width='w-12'
				{...tailwind?.header}>
				<CardImg src={items[0]?.thumbnail} alt='Stack thumbnail' borderRadius='rounded-md' />
			</CardHeader>
		) : (
			<CardHeader aspect='aspect-[1/1]' display='flex-middle' spacing='p-1' width='w-12'>
				{stack.thumbnail ? (
					<CardImg src={stack.thumbnail} alt='stack thumbnail' borderRadius='rounded-md' />
				) : (
					<CardIcon icon='layer-group' />
				)}
			</CardHeader>
		)}
	</Fragment>
);
