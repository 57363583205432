import { useRef, useState } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Fade } from '@playbooks/interface/components';
import { Col, Container, Grid } from '@playbooks/interface/grid';
import { Hero, HeroActions, HeroBg, HeroBody, HeroSubtitle, HeroTitle } from '@playbooks/interface/heros';
import { Div, Span } from '@playbooks/interface/html';
import { BorderLink, Link, TextLink } from '@playbooks/interface/links';
import { gradientSpan } from '@playbooks/interface/utils';
import { useSession, useTheme, useToast } from 'contexts';

const IndexHero = () => {
	const [fade, setFade] = useState('opacity-0');
	const session = useSession();
	const theme = useTheme();
	const toast = useToast();
	const ref = useRef(null);

	// Methods
	const onClick = () => {
		window.navigator.clipboard.writeText(`npm install -g @playbooks/cli`);
		toast.showSuccess(200, 'Command copied!');
	};

	// Render
	return (
		<Hero border='border-b' display='' spacing=''>
			<Div
				bgColor='bg-gradient-to-b from-white/0 via-white/50 to-white/0 dark:from-gray-900/0 dark:via-gray-900/50 dark:to-gray-900/0'
				height='h-full'
				spacing='py-20'
				zIndex='z-10'>
				<Container size='xl'>
					<Grid>
						<Col lg='10'>
							<HeroBody>
								<BorderLink
									size='px-3 py-1.5'
									href={process.env.NEXT_PUBLIC_BLOG_DOMAIN + '/news/welcome-to-playbooks'}
									target='_blank'
									nextIcon='chevron-right'>
									Welcome to Playbooks v1.0!
								</BorderLink>
								<HeroTitle size='h1' display='' space=''>
									<Span spacing='mr-2'>Build faster,</Span>
									<Span {...gradientSpan}>build smarter</Span>.
								</HeroTitle>
								<HeroSubtitle>
									Jumpstart your next project with a premium software template built by your peers.{' '}
									<br className='hidden lg:inline-block' />
									Want to contribute? Explore our bounty program to earn cash building templates for the marketplace.
								</HeroSubtitle>
								<HeroActions
									display='flex-column md:flex-row md:flex-start'
									space='space-y-4 md:space-y-0 md:space-x-4'
									spacing='py-6'
									width='max-w-[600px]'>
									{session.isAuthenticated() ? (
										<Link size='md' nextIcon='chevron-right' href={session.rootLink} className='w-full md:w-1/2'>
											View Profile
										</Link>
									) : (
										<Link size='md' nextIcon='chevron-right' href='/login' className='w-full md:w-1/2'>
											Get Started
										</Link>
									)}
									<BorderBtn size='md' nextIcon='link' onClick={onClick} className='w-full md:w-1/2'>
										$ &nbsp; npm install -g @playbooks/cli
									</BorderBtn>
								</HeroActions>
								<HeroActions display='flex-start' spacing=''>
									<TextLink
										size='xxs'
										fontSize='text-sm'
										href={process.env.NEXT_PUBLIC_DOCS_DOMAIN}
										nextIcon='arrow-up-right'
										target='_blank'>
										View the docs
									</TextLink>
								</HeroActions>
							</HeroBody>
						</Col>
					</Grid>
				</Container>
			</Div>
			<Fade
				ref={ref}
				show={theme.loaded}
				timeout={0}
				onEnter={() => setFade('opacity-0')}
				onEntered={() => setFade('opacity-100')}>
				<HeroBg
					ref={ref}
					bgImage={
						theme.isDark
							? "bg-[url('/animations/roaming-perspective-dark.svg')]"
							: "bg-[url('/animations/roaming-perspective.svg')]"
					}
					bgSize='bg-cover'
					bgPosition='bg-center'
					transition='transition-all'
					fade={fade}
					overflow='overflow-hidden'
				/>
			</Fade>
		</Hero>
	);
};

export { IndexHero };
