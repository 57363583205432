import { Badge } from '@playbooks/interface/badges';
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardIcon,
	CardImg,
	CardPretitle,
	CardSubtitle,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { Div, Span } from '@playbooks/interface/html';
import { Skeleton } from 'molecules/skeletons';

const DisplayActionCard = ({
	icon,
	photo,
	header = null,
	badges,
	status,
	pretitle,
	title,
	subtitle,
	text,
	href,
	meta,
	loading,
	tailwind,
	children,
}) => {
	// Render
	if (loading) return <Skeleton type='display' tailwind={tailwind} />;
	return (
		<Card display='flex-column' height='h-full' {...tailwind?.card}>
			<Div display='flex-end flex-row' position='absolute' inset='top-4 right-4' space='space-x-2'>
				{badges}
				{status && <Badge type={status}>{status}</Badge>}
			</Div>
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				display='flex-middle'
				overflow='overflow-hidden'
				spacing='m-4 p-2'
				width='w-20'
				{...tailwind?.header}>
				{photo ? (
					<CardImg src={photo} alt={title + ' photo'} {...tailwind?.photo} />
				) : (
					<CardIcon icon={icon} {...icon} {...tailwind?.icon} />
				)}
			</CardHeader>
			<CardBody align='text-left' flex='grow' spacing='px-4 pb-4' {...tailwind?.body}>
				{pretitle && <CardPretitle>{pretitle}</CardPretitle>}
				{title && <CardTitle {...tailwind?.title}>{title}</CardTitle>}
				{subtitle && (
					<CardSubtitle className='line-clamp-2' {...tailwind?.subtitle}>
						{subtitle}
					</CardSubtitle>
				)}
				{text && <CardText {...tailwind?.text}>{text}</CardText>}
				{meta && <Span className='line-clamp-1'>{meta}</Span>}
			</CardBody>
			{children && <CardFooter {...tailwind?.footer}>{children}</CardFooter>}
		</Card>
	);
};

export { DisplayActionCard };
