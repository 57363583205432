import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import Intercom from '@intercom/messenger-js-sdk';
import { useSession } from 'contexts/session-context';

type iIntercom = {
	start: any;
	toggle: any;
	show: any;
	hide: any;
	shutdown: any;
	loaded: boolean;
};

const IntercomContext = React.createContext<iIntercom>(null);

const IntercomProvider = ({ children }) => {
	const [active, setActive] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const router = useRouter();
	const session = useSession();

	// Hooks
	useEffect(() => {
		if (active) {
			if (
				router.pathname.includes('/add/bounty/[bountyId]') ||
				router.pathname.includes('/add/repo/[repoId]') ||
				router.pathname.includes('/add/stack/[stackId]') ||
				router.pathname.includes('/add/[submissionId]')
			) {
				return window.Intercom('onHide', () => shutdown());
			}
		}
	}, [active]);

	useEffect(() => {
		if (
			router.pathname.includes('/add/bounty/[bountyId]') ||
			router.pathname.includes('/add/repo/[repoId]') ||
			router.pathname.includes('/add/stack/[stackId]') ||
			router.pathname.includes('/add/[submissionId]')
		) {
			return shutdown();
		} else if (
			!router.pathname.includes('/add/bounty/[bountyId]') &&
			!router.pathname.includes('/add/repo/[repoId]') &&
			!router.pathname.includes('/add/stack/[stackId]') &&
			!router.pathname.includes('/add/[submissionId]')
		) {
			return init();
		}
	}, [router.pathname]);

	useEffect(() => {
		if (session.user.id) update();
	}, [session.user.id]);

	// Methods
	const init = () => {
		Intercom({
			name: session.user?.name,
			email: session.user?.email,
			app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
			hide_default_launcher: false,
		});
		setActive(true);
		setLoaded(true);
	};

	const update = () => {
		Intercom({
			name: session.user?.name,
			email: session.user?.email,
			app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
			hide_default_launcher: active ? false : true,
		});
	};

	const toggle = () => {
		active ? shutdown() : start();
	};

	const start = () => {
		init();
		show();
	};

	const show = () => {
		if (window.Intercom) window.Intercom('show');
	};

	const hide = () => {
		if (window.Intercom) window.Intercom('hide');
	};

	const shutdown = () => {
		if (window.Intercom) window.Intercom('shutdown');
		setActive(false);
	};

	// Render
	return (
		<IntercomContext.Provider value={{ start, toggle, show, hide, shutdown, loaded }}>
			{children}
		</IntercomContext.Provider>
	);
};

const useIntercom = () => {
	return React.useContext(IntercomContext);
};

export { IntercomProvider, useIntercom };

// Docs
// https://developers.intercom.com/installing-intercom/web
// https://developers.intercom.com/installing-intercom/web/installation
// https://developers.intercom.com/installing-intercom/web/methods
// https://developers.intercom.com/installing-intercom/web/attributes-objects
