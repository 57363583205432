export const appParams = {
	page: 0,
	pageSize: 20,
	query: '',
	sortProp: 'id',
	sortValue: 'desc',
};

// Activity
export const activityParams = {
	...appParams,
	sortProp: 'createdAt',
	sortValue: 'desc',
};

// Banks
export const bankParams = {
	...appParams,
	sortProp: 'default',
	sortValue: 'desc',
};

// Banks
export const bookmarkParams = {
	...appParams,
};

// Bounties
export const bountyParams = {
	...appParams,
	include: ['framework', 'language', 'meta', 'platform', 'tool', 'topics'],
	sortProp: 'bounties.status',
	sortValue: 'asc',
};

export const bountyDetailParams = {
	include: [
		'framework',
		'labels',
		'language',
		'medias',
		'meta',
		'platform',
		'requirements',
		'tools',
		'topics',
		'winner.[repo.[framework,language,platform,tool,topic]]',
	],
};

// Cards
export const cardParams = {
	...appParams,
	sortProp: 'default',
	sortValue: 'desc',
};

// Campaigns
export const campaignParams = {
	...appParams,
};

// Charges
export const chargeParams = {
	...appParams,
	include: ['card'],
	sortProp: 'charges.createdAt',
	sortValue: 'desc',
};

export const chargeDetailParams = {
	include: ['card', 'refund'],
};

// Stacks
export const stackParams = {
	...appParams,
	include: ['bounties', 'meta', 'repos'],
	sortProp: 'stacks.name',
	sortValue: 'asc',
};

export const stackDetailParams = {
	include: ['bounties', 'languages', 'frameworks', 'meta', 'platforms', 'repos', 'tools', 'topics'],
};

// Comments
export const commentParams = {
	...appParams,
	sortProp: 'comments.createdAt',
	sortValue: 'asc',
	include: ['meta', 'replies'],
};

export const commentDetailParams = {
	include: ['discussion', 'replies'],
};

// Contacts
export const contactParams = {
	...appParams,
	include: [''],
	sortProp: 'name',
	sortValue: 'asc',
};

// Contributions
export const contributionParams = {
	...appParams,
	sortProp: 'name',
	sortValue: 'asc',
	include: ['contributors'],
};

// Contributors
export const contributorParams = {
	...appParams,
	sortProp: 'name',
	sortValue: 'asc',
};

// Coupons
export const couponParams = {
	...appParams,
	sortProp: 'value',
	sortValue: 'desc',
};

export const couponDetailParams = {
	include: [],
};

// Demos
export const demoParams = {
	...appParams,
	include: ['dockerImage', 'repo'],
	sortProp: 'id',
	sortValue: 'desc',
};

export const demoDetailParams = {
	include: ['dockerImage', 'repo'],
};

// Discussions
export const discussionParams = {
	...appParams,
	include: ['labels', 'meta'],
	sortProp: 'discussions.createdAt',
	sortValue: 'asc',
};

export const discussionDetailParams = {
	include: ['comments.[replies]', 'label', 'meta'],
};

// Downloads
export const downloadParams = {
	...appParams,
	include: [],
};

export const downloadDetailParams = {
	include: [],
};

// Frameworks
export const frameworkParams = {
	...appParams,
	include: ['meta', 'pkgs'],
	sortProp: 'frameworks.name',
	sortValue: 'asc',
};

export const frameworkDetailParams = {
	include: ['meta', 'pkgs'],
};

// Images
export const imageParams = {
	...appParams,
	include: [''],
	sortProp: 'name',
	sortValue: 'asc',
};

export const imageDetailParams = {
	include: [''],
};

// Interests
export const interestParams = {
	...appParams,
};

// Inquiries
export const inquiryParams = {
	...appParams,
	include: ['user'],
};

// Invites
export const inviteParams = {
	...appParams,
};

export const inviteDetailParams = {
	include: ['sender', 'sendee'],
};

// Invoices
export const invoiceParams = {
	...appParams,
	include: ['card', 'subscription.[price,product]'],
};

export const invoiceDetailParams = {
	include: ['card', 'subscription.[price,product]'],
};

// Labels
export const labelParams = {
	...appParams,
	sortProp: 'name',
	sortValue: 'asc',
};

// Languages
export const languageParams = {
	...appParams,
	include: ['meta'],
	sortProp: 'languages.name',
	sortValue: 'asc',
};

// Leads
export const leadParams = {
	...appParams,
};

export const leadDetailParams = {
	include: [],
};

// Licenses
export const licenseParams = {
	...appParams,
	sortProp: 'licenses.name',
	sortValue: 'asc',
	include: [],
};

// Members
export const memberParams = {
	...appParams,
	sortProp: '',
	sortValue: 'asc',
	include: [''],
};

// Newsletters
export const newsletterParams = {
	...appParams,
	include: [''],
};

export const newsletterDetailParams = {
	include: ['meta'],
};

// Notifications
export const notificationParams = {
	include: [],
};

// Orders
export const orderParams = {
	...appParams,
	include: ['card', 'charge', 'version'],
};

export const orderDetailParams = {
	include: ['card', 'charge.[card]', 'version'],
};

// Payouts
export const payoutParams = {
	...appParams,
	include: ['bank'],
};

export const payoutDetailParams = {
	include: ['bank', 'transfers'],
};

// Pins
export const pinParams = {
	...appParams,
	sortProp: 'id',
};

// Pipelines
export const pipelineParams = {
	...appParams,
	sortProp: 'id',
	sortValue: 'desc',
};

// Packages
export const pkgParams = {
	...appParams,
	include: ['license'],
};

export const pkgDetailParams = {
	include: ['framework', 'license', 'tool'],
};

// Platforms
export const platformParams = {
	...appParams,
	include: ['meta'],
	sortProp: 'platforms.name',
	sortValue: 'asc',
};

// Positions
export const positionParams = {
	...appParams,
	include: ['company'],
	sortProp: 'startDate',
	sortValue: 'desc',
};

// Prospects
export const prospectParams = {
	...appParams,
	include: [''],
	sortProp: 'prospects.name',
	sortValue: 'asc',
};

// Prices
export const priceParams = {
	...appParams,
	include: ['product'],
	sortProp: 'amount',
	sortValue: 'asc',
};

export const priceDetailParams = {
	include: ['product'],
};

// Products
export const productDetailParams = {
	include: ['prices'],
};

// Recommendations
export const recommendationParams = {
	...appParams,
	include: ['framework', 'language', 'meta', 'platform', 'tool', 'topic'],
	sortProp: 'name',
	sortValue: 'asc',
};

// Repos
export const repoParams = {
	...appParams,
	include: ['framework', 'language', 'meta', 'platform', 'tool', 'topic'],
	sortProp: 'repos.name',
	sortValue: 'repos.asc',
};

export const repoDetailParams = {
	include: [
		'contributors',
		'demo.[dockerImage]',
		'framework',
		'labels',
		'language',
		'license',
		'medias',
		'meta',
		'platform',
		'quality',
		'template',
		'tools',
		'topics',
		'version',
	],
};

// Reviews
export const reviewParams = {
	...appParams,
	include: ['download.[version]'],
	sortProp: 'overallRating',
	sortValue: 'desc',
};

// Search
export const searchParams = {
	...appParams,
	sortProp: 'count',
	sortValue: 'desc',
};

// Skills
export const skillParams = {
	...appParams,
};

// Submissions
export const submissionParams = {
	...appParams,
	include: ['bounty', 'language', 'framework', 'meta', 'platform', 'tool', 'topic'],
};

export const submissionDetailParams = {
	include: [
		'bounty',
		'demo.[dockerImage]',
		'discussion',
		'language',
		'framework',
		'medias',
		'meta',
		'platform',
		'quality',
		'repo',
		'template',
		'tools',
		'topics',
	],
};

// Subscriptions
export const subscriptionParams = {
	...appParams,
	include: ['price', 'product'],
};

export const subscriptionDetailParams = {
	include: ['price', 'product'],
};

// Taxonomy
export const taxonomyParams = {
	...appParams,
	pageSize: '*',
	sortProp: 'name',
	sortValue: 'asc',
};

// Teams
export const teamParams = {
	...appParams,
	include: ['meta'],
	sortProp: 'teams.name',
	sortValue: 'asc',
};

export const teamDetailParams = {
	include: ['interests(preview)', 'location', 'members(preview)', 'meta', 'skills(preview)', 'setup'],
};

// Templates
export const templateParams = {
	...appParams,
	include: ['dockerImage'],
	sortProp: 'name',
	sortValue: 'asc',
};

export const templateDetailParams = {
	include: ['dockerImage'],
};

// Tools
export const toolParams = {
	...appParams,
	include: ['meta', 'pkgs'],
	sortProp: 'tools.name',
	sortValue: 'asc',
};

export const toolDetailParams = {
	include: ['meta', 'pkgs'],
};

// Topics
export const topicParams = {
	...appParams,
	include: ['meta'],
	sortProp: 'topics.name',
	sortValue: 'asc',
};

// Transfers
export const transferParams = {
	...appParams,
	include: ['charge'],
};

export const transferDetailParams = {
	include: ['charge', 'payout.[bank]'],
};

// Users
export const userParams = {
	...appParams,
	include: ['meta'],
	sortProp: 'users.name',
	sortValue: 'asc',
};

export const userDetailParams = {
	include: ['interests(preview)', 'location', 'meta', 'skills(preview)', 'teams(preview)'],
};

// Versions
export const versionParams = {
	...appParams,
	sortProp: 'name',
	sortValue: 'desc',
};

// Views
export const viewParams = {
	...appParams,
	include: ['repo.[platform, language, framework, tool, topic]'],
};

// VWaitlist
export const waitlistParams = {
	...appParams,
	include: ['user'],
};
