import { useEffect, useState } from 'react';

import { AuthBtn } from 'components/auth/auth-btn';
import { useAccount, useStore } from 'contexts';
import { useAction } from 'hooks';

export const BookmarkBtn = ({ type = 'border', model, rootApi }) => {
	const [bookmark, setBookmark] = useState<any>({});
	const account = useAccount();
	const store = useStore();

	// Hooks
	useEffect(() => {
		setBookmark(model?.social?.bookmark || {});
	}, [model?.social]);

	// Actions
	const [onClick, clickTask] = useAction({}, async () => {
		const response = await store.queryRecord({ url: `${rootApi}/bookmark` });
		setBookmark(response.data || {});
	});

	// Render
	return (
		<AuthBtn
			type={type}
			size='icon'
			alt='bookmark'
			active={bookmark.id && { color: 'cyan-500 dark:cyan-500' }}
			icon={bookmark.id ? 'check' : 'bookmark'}
			taskRunning={clickTask.running}
			onClick={onClick}
		/>
	);
};
